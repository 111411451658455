import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import spriteSVG from "../sprite/sprite-build.svg"
import stepsImg from "../images/Steps@3x.png"

const IndexPage = ({ data }) => {

  useEffect(() => {

  }, [])

  return (
    <Layout>
      <Helmet>
        <body className="home"></body>
      </Helmet>
      <SEO title="Technology" />

      <section className="c-section c-section--pagehead">

        <div className="o-container o-v-middle">
          <div className="o-flex-wrap">
            <h1 className="o-type-6">Use our API to preserve privacy with shared, sensitive information</h1>
            <a href="https://e4ufd97gowu.typeform.com/to/lJPnKDSr" className="c-button">Join the Wait List</a>
          </div>

        </div>

      </section>
      
      <section className="c-section c-section--gradient">

        <div className="o-container">
          
          <div className="">

            <div className="o-grid o-grid--large o-type-2">
              <div className="o-grid__col c-cards c-cards--md u-1/2@md">
                <h2 className="c-cards__title">Proprietary approach to shared cryptography.</h2>
                <p>PAD is powered by a patented cryptographic protocol. With this protocol, it’s possible for a sharer (Alice) to have certainty that a secret has not been decrypted by the recipient (Bob) until he chooses to decrypt it.</p>
              </div>
              <div className="o-grid__col c-cards c-cards--md u-1/2@md">
                <h3 className="c-cards__title">Accountability <br />ledger</h3>
                <p>Central to the transparency of decryption in PAD is the ledger. By observing this ledger, Alice can determine whether a secret has been decrypted, and when relevant, by whom.</p>
              </div>
              <div className="o-grid__col c-cards c-cards--md u-1/2@md">
                <h3 className="c-cards__title">Trustees</h3>
                <p>Are the partially trusted keepers of the means of accessing Alice’s secret. The means of decryption are spread across multiple trustees, so that no one entity can control access. They observe the ledger for access requests and share the means of access.</p>
              </div>
              <div className="o-grid__col c-cards c-cards--md u-1/2@md">
                <h3 className="c-cards__title">Decryption</h3>
                <p>When Bob needs to access the secret, he posts his request to the Accountability ledger. The trustees respond automatically with their secret shares - Alice does not need to participate in an additional transaction.</p>
              </div>

            </div>
          </div>
          

        </div>

      </section>

      <section className="c-section c-section--dark u-pv-none">

        <div className="o-container">

          
          <div className="c-diagram">
            <h2 className="c-diagram__title u-text-center"><span className="u-color-compliment">Encryption</span> <span className="u-color-blue u-ml-x5">Decryption</span></h2>
            
            <div className="o-diagram-grid">
              
              <h2 className="c-diagram__title o-diagram-grid__title u-color-compliment">Encryption</h2>

              <div className="o-diagram-grid__col">
                <div className="c-diagram__block">
                  <div className="c-diagram__content">
                    <h3 className="u-mb o-type-2 u-weight-normal"><span className="c-underline-compliment">STEP</span> <span className="u-color-compliment">01</span></h3>
                    <div className="c">
                      <p>Alice encrypts her secret, and splits up the decryption key </p>
                    </div>
                  </div>
                  <div className="c-diagram__icon">
                    <i>
                    <svg className="u-fill-compliment u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconDevice"}></use></svg>
                    </i>
                  </div>
                </div>
                <div className="c-diagram__block">
                  <div className="c-diagram__content">
                    <h3 className="u-mb o-type-2 u-weight-normal"><span className="c-underline-compliment">STEP</span> <span className="u-color-compliment">02</span></h3>
                    <div className="c">
                      <p>She sends the decryption key shares to the Trustees</p>
                    </div>
                  </div>
                  <div className="c-diagram__icon">
                    <i>
                    <svg className="u-fill-compliment u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconWorld"}></use></svg>
                    </i>
                  </div>
                </div>
                <div className="c-diagram__block">
                  <div className="c-diagram__content">
                    <h3 className="u-mb o-type-2 u-weight-normal"><span className="c-underline-compliment">STEP</span> <span className="u-color-compliment">03</span></h3>
                    <div className="c">
                      <p>Similar to trustees, validators ensure that only legitimate persons can access a secret. They also observe the ledger and respond to legitimate requests.</p>
                    </div>
                  </div>
                  <div className="c-diagram__icon">
                    <i>
                    <svg className="u-fill-compliment u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconCode"}></use></svg>
                    </i>
                  </div>
                </div>
              </div>

              <h2 className="c-diagram__title o-diagram-grid__title u-color-blue">Decryption</h2>

              <div className="o-diagram-grid__col">
                <div className="c-diagram__img"><img src={stepsImg} width="635" height="485" alt="" /></div>
              </div>

              <div className="o-diagram-grid__col">
                <div className="c-diagram__block">
                  <div className="c-diagram__content">
                    <h3 className="u-mb o-type-2 u-weight-normal"><span className="c-underline-blue">STEP</span> <span className="u-color-blue">04</span></h3>
                    <div className="c">
                      <p>If or when Bob needs access to the information, he submits a decryption request to the ledger</p>
                    </div>
                  </div>
                  <div className="c-diagram__icon c-diagram__icon--blue">
                    <i>
                    <svg className="u-fill-blue u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconDevice"}></use></svg>
                    </i>
                  </div>
                </div>
                <div className="c-diagram__block">
                  <div className="c-diagram__content">
                    <h3 className="u-mb o-type-2 u-weight-normal"><span className="c-underline-blue">STEP</span> <span className="u-color-blue">05</span></h3>
                    <div className="c">
                      <p>Trustees see the request and publish their secret shares</p>
                    </div>
                  </div>
                  <div className="c-diagram__icon c-diagram__icon--blue">
                    <i>
                    <svg className="u-fill-blue u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconWorld"}></use></svg>
                    </i>
                  </div>
                </div>
                <div className="c-diagram__block">
                  <div className="c-diagram__content">
                    <h3 className="u-mb o-type-2 u-weight-normal"><span className="c-underline-blue">STEP</span> <span className="u-color-blue">06</span></h3>
                    <div className="c">
                      <p>Once a threshold number of trustees have published their shares, Bob can reconstruct the data</p>
                    </div>
                  </div>
                  <div className="c-diagram__icon c-diagram__icon--blue">
                    <i>
                    <svg className="u-fill-blue u-width-x6" viewBox="0 0 32 32"><use xlinkHref={spriteSVG + "#IconCode"}></use></svg>
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          

        </div>

      </section>

      <section className="c-section c-section--gradient-2" id="roadmap">

        <div className="o-container">

          <h2 className="c-section__subtitle">Product Roadmap</h2>
          
          <div className="o-type-2">
            <p>You can sign up to the service <a href="https://e4ufd97gowu.typeform.com/to/lJPnKDSr">today</a>. This is implemented on a permissioned blockchain (Hyperledger Fabric) and available on Oracle Cloud. The service can be accessed via API.</p>
            <p>(See <a href="https://docs.pad.tech/">Docs</a> for API specs and code samples).</p>
          </div>

          <div className="u-text-center u-mv-x4 u-mv-x8@md"><a href="https://e4ufd97gowu.typeform.com/to/lJPnKDSr" className="c-button">Join the Wait List</a></div>
          
          <div className="o-type-2">
            <p>Anyone can become a trustee, and help to protect the privacy of people around the world. The easiest way to become a trustee is using a Raspberry Pi. The Pi image and instructions to start are also available in <a href="https://docs.pad.tech/">Docs</a></p>
          </div>

          <div className="u-text-center u-mv-x4 u-mv-x8@md"><a href="https://docs.pad.tech/" className="c-button">Docs</a></div>

          <div className="o-type-2 o-grid">
            <div className="o-grid__col u-1/2@md">
              <p>Over the next few weeks we will extend the API further, and then open up direct access to the Blockchain for relevant ledger transactions.</p>
            </div>
            <div className="o-grid__col u-1/2@md">
              <p>We are also assessing public blockchain deployment options. We expect to make further announcements on public blockchain implementations soon.</p>
            </div>
          </div>

        </div>

      </section>
      

      
    </Layout>
  )
}

export default IndexPage
